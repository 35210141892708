.aboutSection{
  background: linear-gradient(90deg, rgba(157,157,157,1) 0%, rgba(126,125,125,1) 25%, rgba(56,56,56,1) 71%);
  display: flex;
  float: right;
  max-width: 1400px;
  padding: 32px 80px;
  gap: 40px;
  height: 1000;
}
.leftSection{
  position: relative;
  display: flex;
  align-items: end;
  justify-content: end;
 
  /* background-color: rgb(0, 136, 255); */

}
.aboutImg1{
  position: absolute;
  left: 51px;
  top: 51px;
 width: 400px;
 border-radius: 20px;
 box-shadow: 5px 5px 5px 2px rgba(0, 0, 0, 0.22);
 border: 2px solid white;
 filter: blur(0.8px);
z-index: 2;

}
.aboutImg2{
  position: absolute;
  border-radius: 20px;
  box-shadow: 5px 5px 5px 2px rgba(0, 0, 0, 0.22);
  border: 2px solid white;
  width: 300px;
z-index: 3;
  left: 100px;
  top: 100px;

}
.aboutImg3{
  border-radius: 20px;
  border: 2px solid white;
  box-shadow: 5px 5px 5px 2px rgba(0, 0, 0, 0.22);
  width: 500px;
  filter: blur(1px);

}
.rightSection{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  float: right;
  padding-left: 50px;
}
h1{
  text-align: center;
}
p{
  text-align: center;
}
.divImage{
  position: absolute;
  width: 600px;
  height: 300px;
  right: 40px;
  top: 77px;
  border-radius: 20px;
}
.start{
  text-align: start;
}
.responsive{
  flex-direction: row;
 gap:20px;
}
.equal{
  height: 40px;
  width: 40px;
}
.footer-equal{
  height: 40px;
  width: 40px;
}

.flex{
  display: flex;
}
.column{
  flex-direction: column;
}


.inputpad{
  padding: 10px;
}
.gap{
  gap: 20px;
}
.round-border{
  border: 2px solid rgb(70, 68, 68);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.footer-round-border{
  border: 2px solid rgb(70, 68, 68);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}


.align{
  align-items: center;
}
.gap{
  gap: 10px;
}
button{
  padding: 10px;
  color: white;
  background-color: black;
  border-radius: 3px;
}
.relative{
  position: relative;
}
.absolute{
  position: absolute;
}
.paddingLeft{
  padding-left: 55px;
}


@media (max-width: 968px){
p{
  font-size: 15px;
}
.responsive{
  flex-direction: column;
 gap:0;
}

}
@media (max-width: 1000px){
.aboutImg1{
  width: 300px;
}
.aboutImg2{
  width: 200px;
}
.aboutImg3{
  width: 400px;
}

}
@media (max-width: 840px){
  .aboutImg1{
display: none;
  }
  .aboutImg2{
   display: none;
  }
.aboutImg3{
  width: 300px;

}

}
@media (max-width: 768px){
  .aboutSection{
    flex-direction: column;
  }

  .leftSection{
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    display: none;
  }
  .aboutImg3{
    display: none;
   }
  .aboutImg2{
    display: none;
  }
  .aboutImg1{
    width: 200px;
    border-radius: 2px;
  
   }
   .start{
    text-align: center;
   }
 
   .responsive{
    flex-direction: column;
  
  }
  .rightSection{
    padding-left: 0px;

  }
}
@media (max-width: 560px){
  .aboutImg3{
    display: none;  }
  .aboutImg2{
    display: none;  }
  .aboutImg1{
    width: 150px;
    border-radius: 2px;
   }
   .responsive{
    flex-direction: column;
   gap:0;
  }
  .paddingLeft{
    padding-left: 30px;
  }
  .equal{
    height: 20px;
    width: 20px;
  }
  .size{
    font-size: 13px;
  }
  .round-border{
    border: 1px solid rgb(70, 68, 68);
  }
}


@media (max-width: 425px){
  .equal{
    height: 40px;
    width: 40px;
  }
  .size{
    font-size: 18px;
  }
  .round-border{
    border: 2px solid rgb(70, 68, 68);
  }
  .paddingLeft{
    padding-left: 55px;
  }
}
@media (max-width: 320px){
  .aboutSection{
    padding: 12px 40px;
  
  }
}


/* contact */

